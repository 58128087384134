import { GetProjectQuery, ProjectI, ProjectStatus } from "_interfaces/crafter-project.interfaces";
import ContentContainer from "components/container";
import SearchInput from "components/search-input";
import Pagination from "components/table/pagination";
import { Columns, Table } from "components/table/table";
import moment from "moment";
import { useState } from "react";
import { Button, Select } from "react-daisyui";
import { IoEye, IoTrash } from "react-icons/io5";
import { useDeleteProjectMutation, useGetCrafterProjectsQuery } from "services/modules/crafter-project";
import ProjectDetail from "./sections/project-detail.modal";
import DeletePopUp from "components/modal/other/Delete";
import { errorHandler } from "services/errorHandler";

export const crafterProjectPageRouteName = 'crafter-project';
const CrafterProjectPage = () => {
  const [params, setParams] = useState<GetProjectQuery>({
    page: 1,
    limit: 10,
    status: ProjectStatus.Pending,
  });
  const { data, isLoading, refetch, isFetching } = useGetCrafterProjectsQuery(params);
  const [deleteProject] = useDeleteProjectMutation();
  const [showDetail, setShowDetail] = useState<{ show: boolean, id?: string }>({ show: false });
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState<{
    open: boolean;
    id?: string;
  }>({ open: false });

  const header: Columns<ProjectI>[] = [
    {
      fieldId: "index",
      label: "No",
    },
    {
      fieldId: "user",
      label: "Creator",
      render: (data) => data.user.username,
    },
    {
      fieldId: "description",
      label: "Description",
      render: (data) => `${data.description.substring(0, 25)}...`,
    },
    {
      fieldId: "likeCount",
      label: "Likes",
    },
    {
      fieldId: "createdAt",
      label: "Created At",
      render: (data) => moment(data.createdAt).format("D MMM YYYY")
    },
    {
      fieldId: "id",
      label: "Action",
      render: (data) => (
        <div className="flex flex-row gap-2">
          <Button
            variant="outline"
            color="info"
            startIcon={<IoEye />}
            onClick={() => setShowDetail({ show: true, id: data.id })}
          >
            View Detail
          </Button>
          <Button
            variant="outline"
            color="error"
            startIcon={<IoTrash />}
            onClick={() => handleDeletePopUp(data.id)}
          >
            Delete
          </Button>
        </div>
      )
    }
  ];

  const handleDeletePopUp = (id?: string) => {
    setIsDeletePopupOpen((prev) => ({ open: !prev.open, id }));
  };

  const handleDeleteProject = async (): Promise<void> => {
    try {
      await deleteProject(isDeletePopupOpen.id!).unwrap();
      handleDeletePopUp();
      refetch();
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <ContentContainer>
      <div className="flex items-center justify-between mb-6">
        <h3 className="text-2xl text-[#262626] font-semibold">
          Crafter Project ({data?.meta.total ?? 0})
        </h3>
        <div className="flex flex-row gap-2">
          <Select className="w-full" value={params.status} onChange={(e) => setParams(prev => ({ ...prev, page: 1, status: e.target.value as ProjectStatus }))}>
            <option value={ProjectStatus.Pending}>Pending</option>
            <option value={ProjectStatus.Approved}>Approved</option>
            <option value={ProjectStatus.Rejected}>Rejected</option>
          </Select>
          <SearchInput
            placeholder="Search"
            onSubmit={({ text }) => {
              setParams(prev => ({ ...prev, page: 1, limit: 10, search: text }));
            }}
          />
        </div>
      </div>
      <Table<ProjectI>
        data={data?.data}
        columns={header}
        loading={isLoading || isFetching}
      />
      <Pagination
        currentPage={params.page}
        totalPages={Math.ceil((data?.meta.total ?? 0) / params.limit)}
        onPageChange={(page) => {
          setParams((prev) => ({ ...prev, page }));
        }}
      />

      {showDetail.id &&
        <ProjectDetail
          id={showDetail.id}
          isOpen={showDetail.show}
          closeModal={(needRefetch) => {
            setShowDetail({ show: false });
            if (needRefetch) refetch();
          }}
        />
      }

      <DeletePopUp
        isOpen={isDeletePopupOpen.open}
        data={"Data"}
        onClose={handleDeletePopUp}
        onEdit={handleDeleteProject}
        menu="Data"
      />
    </ContentContainer>
  )
}

export default CrafterProjectPage;